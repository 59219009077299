import router from '@/router'
import rechargePopup from '@/views/rechargeWithdraw/rechargePopup/index.js'

const handleRechargeComposation = () => {
  const handleRecharge = () => {
    rechargePopup()
  }

  const handleWithdraw = (query, methods) => {
    if (!methods) {
      router.push({ path: `/withdraw`, query })
    } else {
      // router.replace(`/withdraw?${query}`)  query参数全部丢失
      router.replace({
        name: 'withdraw',
        query: {
          current: query,
        },
      })
    }
  }

  return {
    handleRecharge,
    handleWithdraw,
  }
}

export default handleRechargeComposation
