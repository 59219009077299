/*
  vueapp实例挂载组件和全局方法的封装
  不止main.js需要使用，创建函数式组件也需要
**/

import router from '@/router/index'
import store from '@/store/index'
import directive from '@/directive/index'
import vLoading from '@/directive/globalLoading'
import vueI18n from '@/i18n/index'
// 登录、注册
import ShowLogin from '@/componentsFun/Login/index.js'
// game游戏通信
import iframePostMessage from '@/utils/iframePostMessage.js'
// 游戏教程弹窗
import HowToPlay from '@/componentsFun/HowToPlay/index.js'
// 充值
import rechargePopup from '@/views/rechargeWithdraw/rechargePopup/index.js'
/*********ant-design-vue 组件引入star */
import {
  Popover as APopover,
  ListItem as AntListItem,
  Skeleton as AntSkeleton,
  Empty as AntEmpty,
  Space as AntSpace,
  Select as AntSelect,
  DatePicker as AntDatePicker,
  Button as AntButton,
  Input as AntInput,
  InputNumber as AntInputNumber,
  Row as AntRow,
  Col as AntCol,
  Drawer as AntaDrawer,
  Table as AntaTable,
  Pagination as AntaPagination,
  Progress as AntProgress,
  Form as AntaForm,
  Checkbox as AntCheckbox,
  ConfigProvider as AntConfigProvider,
  Modal as AntModal,
} from 'ant-design-vue'
//import 'ant-design-vue/dist/antd.css';
/*********ant-design-vue 组件引入end */
/*********vant 组件引入star */
import {
  // Button as VanButton,
  // popup as VanPopup,
  // icon as VanIcon,
  // loading as VanLoading,
  // image as VanImage,
  // Swipe as VanSwiper,
  // SwipeItem as VanSwipeItem,
  // NoticeBar as VanNoticeBar,
  // Tabs as VanTabs,
  // Tab as VanTab,
  // Row as VanRow,
  // Col as VanCol,
  // List as VanList,
  // PullRefresh as VanPullRefresh,
  // Collapse as VanCollapse,
  // CollapseItem as VanCollapseItem,
  // Tabbar as VanTabbar,
  // TabbarItem as VanTabbarItem,
  // Form as VanForm,
  // Field as VanField,
  // CellGroup as VanCellGroup,
  // Overlay as VanOverlay,
  Lazyload,
} from 'vant'
/*********vant end */
export default function setVueAppConfig(app) {
  /*********ant-design-vue 组件引入star */
  app.use(APopover)
  app.use(AntSkeleton)
  app.use(AntEmpty)
  app.use(AntDatePicker)
  app.use(AntSpace)
  app.use(AntSelect)
  app.use(AntInput)
  app.use(AntInputNumber)
  app.use(AntButton)
  app.use(AntRow)
  app.use(AntCol)
  app.use(AntaDrawer)
  app.use(AntaTable)
  app.use(AntaPagination)
  app.use(AntaForm)
  app.use(AntProgress)
  app.use(AntCheckbox)
  app.use(AntConfigProvider)
  app.use(AntModal)
  /*********ant-design-vue 组件引入end */

  /*********vant 组件引入star */
  // app.use(VanList)
  // app.use(VanPullRefresh)
  // app.use(VanCollapse)
  // app.use(VanCollapseItem)
  // app.use(VanButton)
  // app.use(VanPopup)
  // app.use(VanIcon)
  // app.use(VanLoading)
  // app.use(VanImage)
  // app.use(VanSwiper)
  // app.use(VanSwipeItem)
  // app.use(VanNoticeBar)
  // app.use(VanTabs)
  // app.use(VanTab)
  // app.use(VanRow)
  // app.use(VanCol)
  // app.use(VanTabbar)
  // app.use(VanTabbarItem)
  // app.use(VanForm)
  // app.use(VanField)
  // app.use(VanCellGroup)
  // app.use(VanOverlay)
  // // 懒加载
  app.use(Lazyload, { loading: '1' })
  /*********vant 组件引入end */
  app.use(router)
  app.use(store)
  app.use(vueI18n)
  directive(app)
  /** ****** 全局配置start全局的东西一定要加$符号  ********/
  // 图片基础路径
  app.config.globalProperties.$imgBaseUrl = '/static/kwai/'
  // iframe交互
  app.config.globalProperties.$iframePostMessage = iframePostMessage
  /** ****** 全局配置end  ********/
  /** ****** 函数组件start  ********/
  // 游戏教程
  app.config.globalProperties.$howToPlay = HowToPlay
  // 登录
  app.config.globalProperties.$showLogin = ShowLogin
  // 充值
  app.config.globalProperties.$rechargePopup = rechargePopup

  /** ****** 函数组件end  ********/
}
