import { defineStore } from 'pinia'
// 加密
import { getEncryptPwd } from '@/utils/encipher'
// 指纹绑定
import useFingerPrint from '@/hooks/useFingerPrint'
import useLocalCache from '@/hooks/storage/localStorage'
import useSessionCache from '@/hooks/storage/sessionStorage'
// 用户信息字典
import { userInfo } from '@/dictBase/userInfo'
import currency from 'currency.js'
// socket
import socket from '@/utils/socket'
import resetFun from '@/store/reset.js'
import useAppStore from '@/store/modules/app.js'
import { loginApi, customInfoApi, customBalanceApi, queryThreeDayConfigApi, queryThreeDayBonusApi, receiveThreeDayBonusApi } from '@/api/loginRegister/index'

import { getMessageUnReadCountApi } from '@/api/messageCenter/index.js'
import getPrise from '@/componentsFun/MySecondPopup/index'

import i18n from '@/i18n/index'

const {
  setCacheToken,
  removeCacheToken,
  setCacheUserId,
  getCacheToken,
  removeCacheUserId,
  getCacheUserId,
  setAccountInfo,
  removeAccountInfo,
  removeDownload,
} = useLocalCache()

// const { t } = useI18n()
const useUserInfoStore = defineStore('userInfo', {
  state: () => ({
    token: getCacheToken() || '', // 用户token
    userId: getCacheUserId() || '', // 用户id
    userInfo, // 用户信息
    priseList: [], //领取数组

  }),
  actions: {
    // 登录接口
    async loginApiFun(params) {
      try {
        const result = await loginApi({
          customName: params.customName,
          encryptPwd: getEncryptPwd(params.password),
        })
        this.userId = result.data.userId
        this.token = result.data.access_token
        setCacheToken(result.data.access_token)
        setCacheUserId(result.data.userId)
        // 判断用户是否要保存信息
        if (params.checked) {
          setAccountInfo({
            customName: params.customName,
            password: params.password,
          })
        } else {
          removeAccountInfo()
        }
        // 调用用户信息
        await this.getCustomInfo()
        // websocket 需要链接下
        // 登录成功后连接socket
        socket.socketReload(this.token, this.userId)
        // 登录接口返回 existsFingerprint 为true则代表存在指纹 false不存在
        // 未绑定指纹，需要绑定
        // 浏览器指纹绑定
        if (result?.data?.existsFingerprint === false) {
          const { createFingerprint } = useFingerPrint()
          createFingerprint()
        }

        //新用户三日领取金额
        await this.queryThreeDayConfig()

        useAppStore().hasCloseDownload = false
        useSessionCache().removeCloseDownload()

        return Promise.resolve(result.data)
      } catch (error) {
        console.log(error)
        return Promise.reject(error)
      }
    },
    // 用户信息接口
    async getCustomInfo() {
      try {
        const res = await customInfoApi()
        if (res?.data.balance) {
          res.data.balance = currency(res.data.balance, {
            symbol: '',
            precision: 2,
          }).value
        }
        this.userInfo = res?.data ?? userInfo
        // 调用余额
        this.updateBalance()
        useAppStore().awaitingCollectionCountHttp()
        //获取未读消息数
        await this.getMessageUnReadCount()
        return Promise.resolve(res?.data ?? userInfo)
      } catch (error) {
        return Promise.reject(error)
      }
    },
    // 更新用户余额
    async updateBalance() {
      try {
        const { data, code } = await customBalanceApi()
        if (data && Object.prototype.hasOwnProperty.call(data, 'balance')) {
          this.userInfo.balance = currency(data.balance, {
            symbol: '',
            precision: 2,
          }).value
        }
        return code
      } catch (error) {
        console.log(error)
      }
    },
    // 退出登录
    removeToken() {
      this.token = ''
      this.userId = ''
      this.userInfo = userInfo
      removeDownload()
      removeCacheToken()
      removeCacheUserId()
      // 断开连接socket
      socket.socketReload()
      // 还原整个store
      resetFun()
    },
    // 获取未读消息计数
    async getMessageUnReadCount() {
      try {
        const { data } = await getMessageUnReadCountApi()
        this.userInfo.messageUnReadCount = data
      } catch (err) {
        console.error(err)
      }
    },
    async queryThreeDayConfig() {
      try {
        const { data } = await queryThreeDayConfigApi()
        if (data.open) {
          this.priseList = data
          await this.queryThreeDayBonus()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async queryThreeDayBonus() {
      try {
        const { data } = await queryThreeDayBonusApi()
        //是否可以领取
        if (data.canReceive) {
          const _this = this
          const t = i18n.global.t
          getPrise({
            props: {
              modelValue: true, //展示
              headerText: '', //头部文案
              selfContainer: {
                title: t('logAndReg.login.activity.header'),
                content: `<span style="color: var(--color-auxiliary)">${t('systemSetting.moneySymbol')} ${this.priseList.rules[data.pickupCount].reward}</span>`,
                tips:t('logAndReg.login.activity.tips', [`<span style="color: var(--color-auxiliary)">${this.priseList.firstAmount}</span>`, `<span style="color: var(--color-auxiliary)">${this.priseList.effectiveDays}</span>`]),
              }, //内容
              leftText: t('logAndReg.login.activity.btn'), //左边按钮文案
              rightText: '', //右边按钮文案
              close: () => _this.unMountComponent(data), // 关闭事件
              leftClickEvent: () => _this.unMountComponent(data), //左边按钮事件
              rightClickEvent: () => null, //右边按钮事件
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    },
    async receiveThreeDayBonus(data) {
      try {
        await receiveThreeDayBonusApi({ ...data })
      } catch (error) {
        console.log(error)
      }
    },
    async unMountComponent(data) {
      await this.receiveThreeDayBonus(data)
      //更新余额
      await this.updateBalance()
      getPrise({ type: 'unmount' })
    }
  },
})

export default useUserInfoStore
