import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-0a04a9d1"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createBlock($setup["MyPopup"], {
    modelValue: $setup.show,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = function ($event) {
      return $setup.show = $event;
    }),
    onClose: _cache[1] || (_cache[1] = function () {
      return $setup.termsUse({
        type: 'unmount'
      });
    }),
    "class": "termsUse my-popup--pc",
    zIndex: $setup.getMaxZIndex() + 1000
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.str, function (item) {
        return _openBlock(), _createElementBlock("p", {
          key: item
        }, _toDisplayString(item), 1 /* TEXT */);
      }), 128 /* KEYED_FRAGMENT */))])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue", "zIndex"]);
}