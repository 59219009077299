import "core-js/modules/es.object.define-property.js";
import rechargeRecordsDetailsPopup from '@/views/rechargeWithdraw/rechargeRecordsDetailsPopup/index.js';
import useAppStore from '@/store/modules/app.js';
import { formatDateTimer, moneyFormat } from '@/utils/index.js';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    // 是否选中
    active: {
      type: Boolean,
      "default": false
    },
    data: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    expose();
    var props = __props;
    /** ***引入相关包start*****/

    var appStore = useAppStore();
    /** ***ref、reactive、props，等……end*****/
    var handleDetails = function handleDetails() {
      rechargeRecordsDetailsPopup({
        props: {
          data: props.data
        }
      });
    };

    /** ***函数 end*****/

    /** ***生命周期start*****/

    /** ***生命周期end*****/

    var __returned__ = {
      props: props,
      appStore: appStore,
      handleDetails: handleDetails,
      rechargeRecordsDetailsPopup: rechargeRecordsDetailsPopup,
      useAppStore: useAppStore,
      formatDateTimer: formatDateTimer,
      moneyFormat: moneyFormat
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};