import "core-js/modules/es.object.define-property.js";
import { ref } from 'vue';
import MyPopup from '@/components/MyPopup/index.vue';
import termsUse from './index.js';
import { useI18n } from 'vue-i18n';
import { getMaxZIndex } from '@/utils/index';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  setup: function setup(__props, _ref) {
    var _t;
    var expose = _ref.expose;
    /** ***引入相关包start*****/
    var _useI18n = useI18n(),
      t = _useI18n.t;
    var show = ref(false);
    var str = (_t = t('logAndReg.register.tips15')) === null || _t === void 0 ? void 0 : _t.split('\\n');
    expose({
      show: show
    });
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/

    /** ***函数 end*****/

    /** ***生命周期start*****/

    /** ***生命周期end*****/

    var __returned__ = {
      t: t,
      show: show,
      str: str,
      ref: ref,
      MyPopup: MyPopup,
      termsUse: termsUse,
      useI18n: useI18n,
      getMaxZIndex: getMaxZIndex
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};