import { defineComponent, onMounted, ref, watch, reactive } from 'vue'
// 引用router
import { useRouter, useRoute } from 'vue-router'
import useAppStore from '@/store/modules/app.js'
import ShowLogin from '@/componentsFun/Login/index.js'
import MineVue from '@/views/mine/components/PcMine/PcMine.vue'
import useUserInfoStore from '@/store/modules/userInfo'
import { formatDateTimer, copyText } from '@/utils'
import { CountTo } from 'vue3-count-to'
import { message as AntMessage } from 'ant-design-vue'
import copy from 'copy-to-clipboard'
import handleRechargeComposation from '@/hooks/rechargePopup/index'
import useCopyWriter from '@/store/modules/copywriter'
import DownLoadNav from '../../components/DownLoadNav/index.vue'
import { useI18n } from 'vue-i18n'
import useLocalCache from '@/hooks/storage/localStorage'
import selectCountry from '@/componentsFun/selectCountry/index.js'

export default defineComponent({
  components: {
    CountTo,
    DownLoadNav,
  },
  setup() {
    /** ***ref、reactive、props，等……start*****/
    const { t } = useI18n()
    const router = useRouter()
    const route = useRoute()
    // 路由信息存放
    const routeInfo = ref({})
    // 用户信息
    const userInfoStore = useUserInfoStore()
    // app应用
    const appStore = useAppStore()
    // 当前系统时间
    const timeData = ref()

    const { getCurrentCurrencyFormat } = useLocalCache()
    const { handleRecharge, handleWithdraw } = handleRechargeComposation()

    // 数字滚动效果
    const countData = reactive({
      duration: 1, // 金额变化效果控制，1的时候无动画效果，2000时候动画控制2s
      separator: getCurrentCurrencyFormat() === 'comma' ? '.' : ',', // 千分位分隔符
      decimal: getCurrentCurrencyFormat() === 'comma' ? ',' : '.', // 小数点分隔符
    })
    // 刷新转圈圈
    const rotation = ref(0)

    //移动端提现
    const showWithDraw = ref(false)

    const showMine = ref(false)
    const mineTimer = ref(null)
    // 拿取文案配置
    const copyWriter = useCopyWriter()

    /** ***ref、reactive、props，等……end*****/
    /** ***函数 start*****/
    const handleClickOverlay = () => {
      appStore.toggleSideBar()
    }
    //关闭个人中心
    const closeShowMine = () => {
      mineTimer.value = setTimeout(() => {
        showMine.value = false
      }, 500)
    }

    //打开个人中心
    const startShowMine = () => {
      if (mineTimer.value) clearTimeout(mineTimer.value)
      showMine.value = true
    }
    // 注册登录
    const showLoginFun = (type = 'login') => {
      ShowLogin({ type })
        .then((res) => {
          console.log(res)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    //刷新余额
    const refreshMoney = async () => {
      rotation.value = rotation.value + 1000
      await userInfoStore.updateBalance()
      AntMessage.success(t('mine.success'))
    }
    // 复制
    const handleCopy = (text) => {
      copy(text)
      AntMessage.success(t('mine.success'))
    }
    // 跳转首页
    const homeFun = () => {
      if (route.path === '/home') {
        window.location.reload()
        return
      }
      router.push({
        path: '/home',
      })
    }
    //跳转提现
    const toWithdraw = () => {
      if (appStore.device === 'mobile') {
        appStore.toggleSideBar('close')
      }
      if (!userInfoStore.userInfo.existsWithdrawPwd) {
        AntMessage.warn({
          class: 'withdraw__message',
          top: '400px',
          content: t('mine.isCallbackWithdraw'),
          duration: 2,
        })
        router.push({
          name: 'security',
          query: {
            current: 5,
            isCallbackWithdraw: 0,
          },
        })
      } else {
        // router.push('/withdraw')
        handleWithdraw({ current: 'withdrawHome' })
      }
    }
    const searchFun = () => {
      if (appStore.device === 'mobile') {
        appStore.toggleSideBar('close')
      }
      router.push('/search')
    }

    const toggleWithdraw = () => {
      showWithDraw.value = !showWithDraw.value
      appStore.toggleWithdraw = false
    }

    const changeCountry = () => {
      selectCountry({
        props: {
          showClose: true,
          isLoad: true,
        },
      })
    }
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onMounted(() => {
      // 定时器
      setInterval(() => {
        timeData.value = formatDateTimer(new Date(), 'DD/MM HH:mm:ss')
      }, 1000)
    })
    watch(
      () => userInfoStore.userInfo.balance,
      (val1, val2) => {
        countData.duration = val2 && val1 > val2 ? 2000 : 1
      }
    )

    watch(
      () => appStore.toggleWithdraw,
      (val) => {
        if (val) showWithDraw.value = false
      }
    )
    watch(
      () => router.currentRoute.value,
      (newValue) => {
        routeInfo.value = newValue
      },
      { immediate: true }
    )
    /** ***生命周期end*****/
    return {
      appStore,
      timeData,
      userInfoStore,
      countData,
      showLoginFun,
      refreshMoney,
      handleCopy,
      homeFun,
      rotation,
      handleClickOverlay,
      closeShowMine,
      startShowMine,
      showMine,
      MineVue,
      handleRecharge,
      toWithdraw,
      showWithDraw,
      searchFun,
      copyWriter,
      copyText,
      toggleWithdraw,
      routeInfo,
      changeCountry,
    }
  },
})
