import "core-js/modules/es.object.define-property.js";
import router from '@/router';
import { reactive, ref, watch } from 'vue';
import '@vant/touch-emulator';
/** ***引入相关包end*****/

/** ***ref、reactive，等……start*****/

export default {
  props: {
    visible: {
      type: Boolean,
      // 关闭弹窗
      "default": false
    },
    callback: {
      type: Function,
      // 传入移除节点方法,这里是createApp中的方法
      "default": null
    },
    gameList: {
      type: Object,
      // 传入的游戏数据
      "default": function _default() {}
    },
    isGouterGame: {
      type: Boolean,
      // true 跳转游戏  false就不跳转
      "default": true
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    expose();
    var props = __props;
    /** ***引入相关包start*****/

    var data = reactive({
      howToPlay: true,
      activeIndex: 1
    });
    var isES = ref(process.env.VUE_APP_LANGUAGE_FRAME === 'es-ES');
    // 轮播图切换的索引
    var onChange = function onChange(index) {
      return data.activeIndex = index + 1;
    };
    // 定义游戏类型
    var swiperData = {
      dice: 2,
      ring: 2,
      doubleRing: 3,
      spaceDice: 3,
      digitalLottery: 3,
      pk10Lottery: 5,
      luckyLottery: 3,
      mine: 4,
      crash: 2
    };
    var pageVisible = ref(false); // 弹出框是否显示
    pageVisible.value = props.visible; // 把props参数赋值给pageVisible
    // 获取全局组件实例
    /** ***ref、reactive，等……end*****/
    /** ***函数 start*****************/
    // 跳转到游戏详情页面
    var handleGameDetail = function handleGameDetail(item) {
      // 有tabId的跳转到外部游戏界面，用gameId唯一标识；否则，跳转到自研发游戏界面，用keyWord唯一标识
      if (!props.isGouterGame) {
        closePopFun();
        return;
      }
      var query = {
        gameKey: item.keyWord,
        gameName: item.gameName,
        gameId: item.id
      };
      closePopFun();
      router.push({
        path: "/game/house",
        query: query
      });
    };
    // 关闭弹出框
    var closePopFun = function closePopFun() {
      pageVisible.value = false;
    };
    var handleSplit = function handleSplit(data) {
      return data === null || data === void 0 ? void 0 : data.split('\\n');
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    // 监听显示的消失，需要移除dom
    watch(function () {
      return pageVisible.value;
    }, function (val) {
      !val && props.callback();
    });
    /** ***生命周期end*****/

    var __returned__ = {
      props: props,
      data: data,
      isES: isES,
      onChange: onChange,
      swiperData: swiperData,
      pageVisible: pageVisible,
      handleGameDetail: handleGameDetail,
      closePopFun: closePopFun,
      handleSplit: handleSplit,
      router: router,
      reactive: reactive,
      ref: ref,
      watch: watch
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};