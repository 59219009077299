import request from '@/utils/request'

/**
 * 获取彩金信息
 * @returns
 */
export const fetchTgLottery = (data) => {
  return request({
    url: 'kwai/app/tg/activity/generateReward',
    method: 'post',
    data,
  })
}

/**
 * 获取活动开启状态
 * @returns
 */
export const fetchTgLotteryStatus = (params) => {
  return request({
    url: 'kwai/api/tg/activity/getActivityStatus',
    method: 'get',
    params
  })
}

/**
 * 领取活动彩金
 * @returns
 */
export const receiveTgLottery = (data) => {
  return request({
    url: 'kwai/app/tg/activity/receive',
    method: 'post',
    data,
  })
}

/**
 * 领取活动彩金
 * @returns
 */
export const receiveByTgCode = (data) => {
  return request({
    url: 'kwai/app/tg/activity/receiveByTgCode',
    method: 'post',
    data,
  })
}
