import { createApp } from 'vue'
import defaultComponent from './index.vue'
import vueI18n from '@/i18n/index'
// 使用vue3的createApp,以及mount,unmount方法创建挂载实例

function createDefaultComponent(resolve, reject, options) {
  // 创建一个节点，并将组件挂载上去
  const mountNode = document.createElement('div')
  document.body.appendChild(mountNode)
  const app = createApp(defaultComponent, {
    ...options,
    visible: true,
    callback() {
      // 避免重复挂载
      if (window.HowToPlayVisible) {
        window.HowToPlayVisible = false
      }
      setTimeout(() => {
        app.unmount(mountNode) // 创建完后要进行销毁
        document.body.removeChild(mountNode)
      }, 1500)
    },
  })
  app.use(vueI18n)
  app.mount(mountNode)
  window.HowToPlayVisible = true
}

export default function showUserAgreement(options) {
  return new Promise((resolve, reject) => {
    // 防止多次挂载
    if (window.HowToPlayVisible) {
      return resolve(true)
    }
    createDefaultComponent(resolve, reject, options)
  })
}
