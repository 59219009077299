import "core-js/modules/es.array.concat.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-34aa165a"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "wrapper"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  "class": "title"
};
var _hoisted_4 = {
  "class": "content-wrap"
};
var _hoisted_5 = ["innerHTML"];
var _hoisted_6 = ["onClick"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_van_swipe_item = _resolveComponent("van-swipe-item");
  var _component_van_swipe = _resolveComponent("van-swipe");
  var _component_a_button = _resolveComponent("a-button");
  var _component_van_icon = _resolveComponent("van-icon");
  var _component_van_overlay = _resolveComponent("van-overlay");
  return _openBlock(), _createBlock(_component_van_overlay, {
    "class-name": "backdropFilter",
    "z-index": "14",
    show: $setup.pageVisible,
    onClick: _cache[3] || (_cache[3] = function ($event) {
      return $setup.pageVisible = false;
    })
  }, {
    "default": _withCtx(function () {
      return [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
        "class": "block",
        onClick: _cache[2] || (_cache[2] = _withModifiers(function () {}, ["stop"]))
      }, [_createVNode(_component_van_swipe, {
        "class": "play-img-box",
        autoplay: 4000,
        "indicator-color": "#ffea2f",
        onChange: $setup.onChange
      }, {
        "default": _withCtx(function () {
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.swiperData[$props.gameList.keyWord], function (item, i) {
            return _openBlock(), _createBlock(_component_van_swipe_item, {
              key: i,
              onDragstart: _cache[0] || (_cache[0] = _withModifiers(function () {}, ["prevent", "stop"]))
            }, {
              "default": _withCtx(function () {
                return [_createElementVNode("img", {
                  width: "303",
                  height: "180",
                  src: require("@/assets/images/howplay/".concat($setup.isES ? 'es-ES/' : '').concat($props.gameList.keyWord, "_").concat(item, ".png")),
                  alt: ""
                }, null, 8 /* PROPS */, _hoisted_2)];
              }),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */);
          }), 128 /* KEYED_FRAGMENT */))];
        }),
        _: 1 /* STABLE */
      }), _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('howtoplay.title')), 1 /* TEXT */), _createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.handleSplit(_ctx.$t("howtoplay.howplay_".concat($props.gameList.keyWord, "_").concat($setup.data.activeIndex))), function (it, index) {
        return _openBlock(), _createElementBlock("span", {
          key: index,
          "class": "content",
          innerHTML: it
        }, null, 8 /* PROPS */, _hoisted_5);
      }), 128 /* KEYED_FRAGMENT */))]), _createVNode(_component_a_button, {
        "class": "default-btn",
        type: "primary",
        onClick: _cache[1] || (_cache[1] = _withModifiers(function ($event) {
          return $setup.handleGameDetail($props.gameList);
        }, ["stop"]))
      }, {
        "default": _withCtx(function () {
          return [_createTextVNode(_toDisplayString(_ctx.$t('howtoplay.button')), 1 /* TEXT */)];
        }),
        _: 1 /* STABLE */
      }), _createElementVNode("div", {
        "class": "close-module",
        onClick: _withModifiers($setup.closePopFun, ["stop"])
      }, [_createVNode(_component_van_icon, {
        name: "cross",
        color: "white",
        size: "20"
      })], 8 /* PROPS */, _hoisted_6)])])];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["show"]);
}