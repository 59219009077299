import { defineStore } from 'pinia'
import { gameTypeDictApi } from '@/api/dict/index.js'
import vueI18n from '@/i18n/index'
const { t } = vueI18n.global
// 字典表
const useDictStore = defineStore('useDict', {
  state: () => ({
    // 游戏类型字典数据数组形式
    gameTypeData: null,
    // 游戏类型字典数据对象key形式
    gameTypeDataKeys: null,
    // 日期筛选的数据
    dateSelectData: [
      {
        text: t('dict.dateSelectData.today'),
        value: 0,
      },
      {
        text: t('dict.dateSelectData.yesterday'),
        value: -1,
      },
      {
        text: t('dict.dateSelectData.7days'),
        value: -7,
      },
      {
        text: t('dict.dateSelectData.15days'),
        value: -15,
      },
      {
        text: t('dict.dateSelectData.30days'),
        value: -30,
      },
      {
        text: t('dict.dateSelectData.all'),
        value: null,
      },
    ],
    // 提现记录 "status",//提现状态（0-成功；1-失败；2-审核中；3-拒绝; 4-提现中）
    withdrawStatusKeys: {
      0: {
        text: t('dict.withdrawStatus0'),
        value: 0,
      },
      1: {
        text: t('dict.withdrawStatus1'),
        value: 1,
      },
      2: {
        text: t('dict.withdrawStatus2'),
        value: 2,
      },
      3: {
        text: t('dict.withdrawStatus3'),
        value: 3,
      },
      4: {
        text: t('dict.withdrawStatus4'),
        value: 4,
      },
      8: {
        text: t('dict.withdrawStatus0'),
        value: 0,
      },
    },
    // 提现打码表打码类型
    withdrawMultiplierTableCodeTypeKeys: {
      0: {
        text: t('dict.withdrawMultiplierTable.codeType0'),
        value: '0',
      },
      1: {
        text: t('dict.withdrawMultiplierTable.codeType1'),
        value: '1',
      },
      2: {
        text: t('dict.withdrawMultiplierTable.codeType2'),
        value: '2',
      },
      3: {
        text: t('dict.withdrawMultiplierTable.codeType3'),
        value: '3',
      },
      4: {
        text: t('dict.withdrawMultiplierTable.codeType4'),
        value: '4',
      },
      5: {
        text: t('dict.withdrawMultiplierTable.codeType5'),
        value: '5',
      },
      6: {
        text: t('dict.withdrawMultiplierTable.codeType6'),
        value: '6',
      },
      7: {
        text: t('dict.withdrawMultiplierTable.codeType7'),
        value: '7',
      },
      8: {
        text: t('dict.withdrawMultiplierTable.codeType8'),
        value: '8',
      },
      9: {
        text: t('dict.withdrawMultiplierTable.codeType9'),
        value: '9',
      },
      10: {
        text: t('dict.withdrawMultiplierTable.codeType10'),
        value: '10',
      },
      11: {
        text: t('dict.withdrawMultiplierTable.codeType11'),
        value: '11',
      },
      12: {
        text: t('dict.withdrawMultiplierTable.codeType12'),
        value: '12',
      },
      13: {
        text: t('dict.withdrawMultiplierTable.codeType13'),
        value: '13',
      },
      15: {
        text: t('dict.withdrawMultiplierTable.codeType15'),
        value: '15',
      },
      16: {
        text: t('dict.withdrawMultiplierTable.codeType16'),
        value: '16',
      },
      17: {
        text: t('dict.withdrawMultiplierTable.codeType17'),
        value: '17',
      },
      19: {
        text: t('dict.withdrawMultiplierTable.codeType19'),
        value: '19',
      },
      22: {
        text: t('dict.withdrawMultiplierTable.codeType22'),
        value: '22',
      },
    },
  }),
  actions: {
    // 处理字典数据数组形式
    processData(arr = []) {
      return arr.map((item) => {
        item.text = item.dictLabel
        item.value = item.dictValue
        return item
      })
    },
    // 处理字典数据对象key形式
    processDataKeys(arr = []) {
      let newArrKeys = {}
      arr.forEach((item) => {
        item.text = item.dictLabel
        item.value = item.dictValue
        newArrKeys[item.dictValue] = item
      })
      return newArrKeys
    },
    // 游戏类型字典表
    async gameTypeDictHttp() {
      if (this.gameTypeData) {
        return {
          gameTypeData: this.gameTypeData,
          gameTypeDataKeys: this.gameTypeDataKeys,
        }
      }

      try {
        let { data } = await gameTypeDictApi({})
        //全部
        data.unshift({
          searchValue: null,
          params: {},
          dictCode: null,
          dictSort: null,
          dictLabel: 'Tudos Tipo',
          dictValue: null,
          dictType: null,
          cssClass: null,
          listClass: null,
          isDefault: null,
          status: null,
          createBy: null,
          createTime: null,
          updateBy: null,
          updateTime: null,
          remark: null,
          default: true,
        })
        this.gameTypeData = this.processData(data)
        this.gameTypeDataKeys = this.processDataKeys(data)
        return {
          gameTypeData: this.gameTypeData,
          gameTypeDataKeys: this.gameTypeDataKeys,
        }
      } catch (error) {
        return Promise.reject(error)
      }
    },
  },
})

export default useDictStore
