import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '../../../assets/images/common/default-img.png';
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-053d2ac5"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "norem nav-module"
};
var _hoisted_2 = {
  "class": "logo-module"
};
var _hoisted_3 = ["src"];
var _hoisted_4 = ["src"];
var _hoisted_5 = {
  "class": "time-module"
};
var _hoisted_6 = {
  key: 2,
  "class": "money-charge-module"
};
var _hoisted_7 = {
  "class": "money-module"
};
var _hoisted_8 = ["src"];
var _hoisted_9 = {
  "class": "money"
};
var _hoisted_10 = {
  "class": "userinfo-module"
};
var _hoisted_11 = {
  key: 0,
  "class": "not-logged"
};
var _hoisted_12 = {
  "class": "head-viplevel"
};
var _hoisted_13 = ["src"];
var _hoisted_14 = {
  key: 1,
  src: _imports_0,
  alt: ""
};
var _hoisted_15 = {
  "class": "nickname-viplevel"
};
var _hoisted_16 = {
  "class": "viplevel"
};
var _hoisted_17 = {
  "class": "nickname"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$copyWriter$appIn, _ctx$appStore$selectC;
  var _component_count_to = _resolveComponent("count-to");
  var _component_a_button = _resolveComponent("a-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("i", {
    "class": "iconfont icon-a-Frame1171277009 arrow",
    style: _normalizeStyle({
      transform: "rotate(".concat(_ctx.appStore.sideOpen ? '180deg' : '0deg', ")")
    }),
    onClick: _cache[0] || (_cache[0] = function ($event) {
      return _ctx.handleClickOverlay();
    })
  }, null, 4 /* STYLE */), _ctx.copyWriter.site === 'no' ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    "class": "logo",
    onClick: _cache[1] || (_cache[1] = function () {
      return _ctx.homeFun && _ctx.homeFun.apply(_ctx, arguments);
    }),
    src: "/".concat(_ctx.appStore.themeSystem, "/logo/logo.png?v=1"),
    alt: "logo"
  }, null, 8 /* PROPS */, _hoisted_3)) : (_openBlock(), _createElementBlock("img", {
    key: 1,
    "class": "logo",
    onClick: _cache[2] || (_cache[2] = function () {
      return _ctx.homeFun && _ctx.homeFun.apply(_ctx, arguments);
    }),
    src: (_ctx$copyWriter$appIn = _ctx.copyWriter.appInfo) === null || _ctx$copyWriter$appIn === void 0 || (_ctx$copyWriter$appIn = _ctx$copyWriter$appIn.logoList[2]) === null || _ctx$copyWriter$appIn === void 0 ? void 0 : _ctx$copyWriter$appIn.url,
    alt: ""
  }, null, 8 /* PROPS */, _hoisted_4)), _createElementVNode("div", _hoisted_5, [_createElementVNode("h1", null, _toDisplayString(_ctx.timeData), 1 /* TEXT */), _createElementVNode("h2", null, "(UTC" + _toDisplayString((_ctx$appStore$selectC = _ctx.appStore.selectCountryObj) === null || _ctx$appStore$selectC === void 0 ? void 0 : _ctx$appStore$selectC.zoneOffset) + ")", 1 /* TEXT */)]), _ctx.userInfoStore.token ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, [_createElementVNode("img", {
    src: require("@/assets/images/common/country/".concat(_ctx.appStore.language, ".png")),
    alt: ""
  }, null, 8 /* PROPS */, _hoisted_8), _createElementVNode("span", _hoisted_9, [_createVNode(_component_count_to, {
    startVal: 0,
    endVal: _ctx.userInfoStore.userInfo.balance,
    decimals: 2,
    decimal: _ctx.countData.decimal,
    separator: _ctx.countData.separator,
    duration: _ctx.countData.duration
  }, null, 8 /* PROPS */, ["endVal", "decimal", "separator", "duration"])]), _createElementVNode("i", {
    "class": "iconfont icon-Frame2 refresh",
    onClick: _cache[3] || (_cache[3] = function () {
      return _ctx.refreshMoney && _ctx.refreshMoney.apply(_ctx, arguments);
    }),
    style: _normalizeStyle({
      transform: "rotate(".concat(_ctx.rotation, "deg)")
    })
  }, null, 4 /* STYLE */)]), _createVNode(_component_a_button, {
    type: "primary",
    "class": "default-btn",
    onClick: _ctx.handleRecharge
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t('layout.Deposit')), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick"]), _createVNode(_component_a_button, {
    type: "ghost",
    onClick: _ctx.toWithdraw,
    "class": "default-btn-ghost"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t('layout.Withdraw')), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["onClick"])])) : _createCommentVNode("v-if", true)]), _createElementVNode("div", _hoisted_10, [_createCommentVNode(" <div\r\n        v-if=\"(userInfoStore.userInfo.changeCountry && appStore.device === 'desktop') || !userInfoStore.token\"\r\n        @click=\"changeCountry\"\r\n        class=\"select-country\"\r\n      >\r\n        <p>{{ appStore.selectCountryObj.name }}</p>\r\n        <i class=\"iconfont icon-arrow-down\"></i>\r\n      </div> "), !_ctx.userInfoStore.token ? (_openBlock(), _createElementBlock("div", _hoisted_11, [_createVNode(_component_a_button, {
    type: "primary",
    onClick: _cache[4] || (_cache[4] = function ($event) {
      return _ctx.showLoginFun('login');
    }),
    "class": "default-btn"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t('layout.Login')), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  }), _createVNode(_component_a_button, {
    type: "ghost",
    onClick: _cache[5] || (_cache[5] = function ($event) {
      return _ctx.showLoginFun('register');
    }),
    "class": "default-btn-ghost"
  }, {
    "default": _withCtx(function () {
      return [_createTextVNode(_toDisplayString(_ctx.$t('layout.Register')), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    "class": "userinfo-module_center",
    onMouseover: _cache[7] || (_cache[7] = function () {
      return _ctx.startShowMine && _ctx.startShowMine.apply(_ctx, arguments);
    }),
    onMouseleave: _cache[8] || (_cache[8] = function () {
      return _ctx.closeShowMine && _ctx.closeShowMine.apply(_ctx, arguments);
    })
  }, [_createElementVNode("div", _hoisted_12, [_ctx.userInfoStore.userInfo.headUrl ? (_openBlock(), _createElementBlock("img", {
    key: 0,
    src: _ctx.$imgBaseUrl + _ctx.userInfoStore.userInfo.headUrl,
    alt: ""
  }, null, 8 /* PROPS */, _hoisted_13)) : (_openBlock(), _createElementBlock("img", _hoisted_14))]), _createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, "VIP " + _toDisplayString(_ctx.userInfoStore.userInfo.vipNum), 1 /* TEXT */), _createElementVNode("div", _hoisted_17, [_createElementVNode("h1", null, _toDisplayString(_ctx.userInfoStore.userInfo.nickName), 1 /* TEXT */), _createElementVNode("i", {
    onClick: _cache[6] || (_cache[6] = function ($event) {
      return _ctx.copyText(_ctx.userInfoStore.userInfo.nickName);
    }),
    "class": "iconfont icon-Frame-1"
  })])]), _createElementVNode("i", {
    "class": _normalizeClass(["iconfont icon-arrow-down", [{
      active: _ctx.showMine
    }]])
  }, null, 2 /* CLASS */), (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.MineVue), {
    "class": _normalizeClass([{
      active: _ctx.showMine
    }, {
      unactive: !_ctx.showMine
    }])
  }, null, 8 /* PROPS */, ["class"]))], 32 /* HYDRATE_EVENTS */))])]);
}